import React from 'react'
import aboutImg1 from '../../assets/images/about/about-img1.png'

const AboutUs = () => {
    return (
        <section className="overview-area ptb-100 pt-0">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">Descripción </span>
                    <h2>Planta telefónica</h2>
                    <p>Soluciones a necesidades de comunicación telefónica con tecnología ASTERISK, con experiencia en el campo de implementación, manejo y mejores practicas en el ramo de la tecnología y procesos de Call Center de entrada y salida</p>
                </div>




                <div className="overview-box">
                    <div className="overview-content">
                        <div className="content">
                            <span className="sub-title">Servicios</span>
                            <h2>Servicios</h2>
                            <p>Nos encargamos de dar soluciones en administración de Call Center  de manera profesional.</p>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i>Implementación de solución PBX basada en Asterisk </h4>
                            </div>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i>Implementación de Software Estadístico e indicadores telefónicos</h4>
                            </div>
                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i>Consultoría y diagnóstico de necesidades basadas en telefonía</h4>
                            </div>
                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i>Hardware dedicado a soluciones telefónicas</h4>
                               
                            </div>
                        </div>
                    </div>

                    <div className="overview-image">
                        <div className="image">
                            <img src={aboutImg1} alt="about" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutUs