import React from 'react'
import {Link} from 'gatsby'

const Pricing = () => {
    return (
        <section className="pricing-area pt-100 pb-70 bg-f4f7fe">
        <div className="container">
            <div className="section-title">
                <h2>Más de nuestros servicios</h2>
                <p>Te mostramos las características que cuenta cada uno de nuestro servicio de desarrollo</p>
            </div>

            <div className="row">
                <div className="col-lg-4 col-md-6">
                    <div className="single-pricing-box">
                        <div className="pricing-header">
                            <h3>Desarrollo web </h3>
                        </div>

                        {/* <div className="price">
                            <sup>$</sup> 120 <sub>/ Per week</sub>
                        </div> */}


                        <ul className="pricing-features">
                        <li><i className='bx bxs-badge-check'></i> SEO </li>
                            <li><i className='bx bxs-badge-check'></i> Hosting</li>
                            <li><i className='bx bxs-badge-check'></i> Dominio</li>
                            <li><i className='bx bxs-badge-check'></i> Certificado SSL</li>
                            <li><i className='bx bxs-badge-check'></i> Diseño</li>
                            <li><i className='bx bxs-badge-check'></i> Adaptabilidad en dispositivos</li>
                            <li><i className='bx bxs-badge-check'></i> Soporte técnico</li>
                        </ul>

                      <div className="btn-box">
                            <Link to="/desarrollo-web" className="default-btn">
                                <i className="flaticon-tick"></i> 
                                Ver servicio
                                <span></span>
                            </Link>
                        </div> 
                    </div>
                </div>

                <div className="col-lg-4 col-md-6">
                    <div className="single-pricing-box">
                        <div className="pricing-header">
                            <h3>Desarrollo de aplicaciones móviles</h3>
                        </div>

                        {/* <div className="price">
                            <sup>$</sup> 840 <sub>/ Per month</sub>
                        </div> */}

                        <ul className="pricing-features">
                        <li><i className='bx bxs-badge-check'></i> Diseño UX/UI</li>
                            <li><i className='bx bxs-badge-check'></i>Pruebas (Testing)</li>
                            <li><i className='bx bxs-badge-check'></i> Google Analytics</li>
                            <li><i className='bx bxs-badge-check'></i> Analíticas e informes</li>
                            <li><i className='bx bxs-badge-check'></i>Subía en tiendas móviles</li>
                        
                            <li><i className='bx bxs-badge-check'></i> Soporte técnico</li>
                        </ul>

                     <div className="btn-box">
                            <Link to="/desarrollo-movil" className="default-btn">
                                <i className="flaticon-tick"></i> 
                                Ver servicio
                                <span></span>
                            </Link>
                        </div> 


                    </div>
                </div>

                <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                    <div className="single-pricing-box">
                        <div className="pricing-header">
                            <h3>Planta telefónica</h3>
                        </div>

                        {/* <div className="price">
                            <sup>$</sup> 3,600 <sub> / Per yearly</sub>
                        </div> */}

                        <ul className="pricing-features">
                            <li><i className='bx bxs-badge-check'></i> Integración de datos</li>
                            <li><i className='bx bxs-badge-check'></i>Análisis de información </li>
                            <li><i className='bx bxs-badge-check'></i> Informe de historial de llamadas</li>
                          
                            <li><i className='bx bxs-badge-check'></i> Soporte técnico</li>
                        </ul>

                        <div className="btn-box">
                            <Link to="/planta-telefonica" className="default-btn">
                                <i className="flaticon-tick"></i> 
                                Ver servicio
                                <span></span>
                            </Link>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
}

export default Pricing