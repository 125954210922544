import React from 'react'
import {Link} from 'gatsby'
import ReactWOW from 'react-wow'


import planta from '../../assets/images/planta.png'

import Loadable from '@loadable/component'
const ModalVideo = Loadable(() => import('react-modal-video'))


const MainBanner = () => {
    const [isOpen, setIsOpen] = React.useState(true);
    const openModal = () => {
        setIsOpen(!isOpen);
    }

    return (
        <React.Fragment>
            {/* If you want to change the video need to update videoID */}
            <ModalVideo 
                channel='youtube' 
                isOpen={!isOpen} 
                videoId='bk7McNUjWgw' 
                onClose={() => setIsOpen(!isOpen)} 
            />

            <div className="banner-section2">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-5 col-md-12">
                            <div className="banner-wrapper-content">

                            <span className="sub-title">PLANTA TELEFÓNICA</span>


                            
                                <ReactWOW delay='.1s' animation='fadeInLeft'>
                                    <h1>Comunícate, gestiona y atiende de una manera más fácil.</h1>
                                </ReactWOW>

                                <ReactWOW delay='.1s' animation='fadeInLeft'>
                                    <p>Si necesitas más información sobre este servicio presiona el botón</p>
                                </ReactWOW>

                                <ReactWOW delay='.1s' animation='fadeInRight'>
                                    <div className="btn-box">
                                        <Link to="/contact" className="default-btn">
                                            <i className="flaticon-right"></i> 
                                            Contáctanos <span></span>
                                        </Link>

                                     {/*    <Link 
                                            to="#"
                                            onClick={e => {e.preventDefault(); openModal()}}
                                            className="video-btn popup-youtube"
                                        >
                                            <i className="flaticon-google-play"></i> Ver video
                                        </Link> */}
                                    </div>
                                </ReactWOW>
                            </div>
                        </div>

                        <div className="col-lg-7 col-md-12">
                           {/*  <div className="banner-animation-image">
                            <ReactWOW delay='.1s' animation='fadeInDown'>
                                    <img src={img1} alt="banner" />
                                </ReactWOW>
                                <ReactWOW delay='.1s' animation='fadeInDown'>
                                    <img src={img2} alt="banner" />
                                </ReactWOW>
                                <ReactWOW delay='.1s' animation='fadeInDown'>
                                    <img src={img3} alt="banner" />
                                </ReactWOW>
                                <ReactWOW delay='.1s' animation='fadeInDown'>
                                    <img src={img4} alt="banner" />
                                </ReactWOW>
                                <ReactWOW delay='.1s' animation='fadeInUp'>
                                    <img src={img5} alt="banner" />
                                </ReactWOW>
                                <ReactWOW delay='.1s' animation='fadeInDown'>
                                    <img src={img6} alt="banner" />
                                </ReactWOW>
                                <ReactWOW delay='.1s' animation='fadeInDown'>
                                    <img src={img7} alt="banner" />
                                </ReactWOW>
                                <ReactWOW delay='.1s' animation='fadeInDown'>
                                    <img src={img8} alt="banner" />
                                </ReactWOW>
                                <ReactWOW delay='.1s' animation='fadeInDown'>
                                    <img src={img9} alt="banner" />
                                </ReactWOW>
                                <ReactWOW delay='.1s' animation='fadeInDown'>
                                    <img src={img10} alt="banner" />
                                </ReactWOW>
                                <ReactWOW delay='.1s' animation='fadeInDown'>
                                    <img src={img11} alt="banner" />
                                </ReactWOW>
                                <ReactWOW delay='.1s' animation='fadeInLeft'>
                                    <img src={img12} alt="banner" />
                                </ReactWOW>

                                <img src={img13} alt="banner" />
                            </div> */}

<ReactWOW delay='.1s' animation='fadeInUp'>
                            <div className="banner-wrapper-image">
                                <img src={planta} alt="banner" />
                            </div>
                        </ReactWOW>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default MainBanner
