import React from 'react'
import {Link} from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'
import serviceIcon1 from '../../assets/images/services/service-icon1.png'
import serviceIcon2 from '../../assets/images/services/service-icon2.png'
import serviceIcon3 from '../../assets/images/services/service-icon3.png'
import serviceIcon4 from '../../assets/images/services/service-icon7.png'
import serviceIcon5 from '../../assets/images/services/service-icon8.png'
import serviceIcon6 from '../../assets/images/services/service-icon9.png'
import serviceIcon7 from '../../assets/images/services/service-icon10.png'
import serviceIcon8 from '../../assets/images/services/service-icon11.png'
import serviceIcon9 from '../../assets/images/services/service-icon12.png'


const FeaturedServices = () => {
    return (
        <section className="services-area pt-100 pb-70 bg-f1f8fb">
            <div className="container">
                <div className="section-title">


                <span className="sub-title">
                      {/*   <img src={StarIcon} alt="StarIcon" />  */}
                      SERVICIOS
                    </span>
           
                    <h2>Característica</h2>
                   {/*  <p>Te presentamos como se desglosa las funcionalidades de nuestro servicio de planta telefónica</p> */}
                </div>

                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon1} alt="features" />
                            </div>
                            <h3>
                                <Link to="/service-details">
                                Basado en tecnología ASTERISK 
                                </Link>
                            </h3>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p> */}
                            {/* <Link to="/service-details" className="read-more-btn">
                                Read More 
                                <i className="flaticon-right"></i>
                            </Link> */}
                            
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon2} alt="features" />
                            </div>
                            <h3>
                                <Link to="/service-details">
                                Acceso a Información en tiempo real 
                                </Link>
                            </h3>
                        
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p> */}

                            {/* <Link to="/service-details" className="read-more-btn">
                                Read More 
                                <i className="flaticon-right"></i>
                            </Link> */}
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon3} alt="features" />
                            </div>

                            <h3>
                                <Link to="/service-details">
                                    Control Call Center
                                </Link>
                            </h3>
 
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p> */}

                            {/* <Link to="/service-details" className="read-more-btn">
                                Read More 
                                <i className="flaticon-right"></i>
                            </Link> */}
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon4} alt="features" />
                            </div>
                            <h3>
                                <Link to="/service-details">
                                Colas de llamada 
                                </Link>
                            </h3>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p> */}
                            {/* <Link to="/service-details" className="read-more-btn">
                                Read More 
                                <i className="flaticon-right"></i>
                            </Link> */}
                            
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon5} alt="features" />
                            </div>
                            <h3>
                                <Link to="/service-details">
                                Registros históricos de llamadas
                                </Link>
                            </h3>
                        
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p> */}

                            {/* <Link to="/service-details" className="read-more-btn">
                                Read More 
                                <i className="flaticon-right"></i>
                            </Link> */}
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon6} alt="features" />
                            </div>

                            <h3>
                                <Link to="/service-details">
                                Seguridad de la información
                                </Link>
                            </h3>
 
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p> */}

                            {/* <Link to="/service-details" className="read-more-btn">
                                Read More 
                                <i className="flaticon-right"></i>
                            </Link> */}
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon7} alt="features" />
                            </div>
                            <h3>
                                <Link to="/service-details">
                                Integridad de la información 
                                </Link>
                            </h3>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p> */}
                            {/* <Link to="/service-details" className="read-more-btn">
                                Read More 
                                <i className="flaticon-right"></i>
                            </Link> */}
                            
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon8} alt="features" />
                            </div>
                            <h3>
                                <Link to="/service-details">
                                Tecnologías de Marcado Automático
                                </Link>
                            </h3>
                        
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p> */}

                            {/* <Link to="/service-details" className="read-more-btn">
                                Read More 
                                <i className="flaticon-right"></i>
                            </Link> */}
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon9} alt="features" />
                            </div>

                            <h3>
                                <Link to="/service-details">
                                Registro de llamados 
                                </Link>
                            </h3>
 
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p> */}

                            {/* <Link to="/service-details" className="read-more-btn">
                                Read More 
                                <i className="flaticon-right"></i>
                            </Link> */}
                        </div>
                    </div>
                </div>




            </div>
        </section>
    )
}

export default FeaturedServices